body {
	margin: 0;
	font-family: "Montserrat", "Cinzel";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #080523 !important;
}

code {
	font-family: Montserrat, Cinzel, monospace;
}

.MuiDialog-paperScrollPaper {
	background: #080523;
	width: 453px;
	/* height: 612px; */
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 30px !important;
	padding: 4px;
	z-index: 10000000 !important;
}
.dialoge__content__section {
	background: #080523;
	border-radius: 30px;
	width: 100%;
	height: 100%;
	z-index: 10000000 !important;
}

@media screen and (max-width: 544px) {
	.MuiDialog-paperScrollPaper {
		width: 100%;
	}
}

.wcm-overlay {
	z-index: 1000000000 !important;
}
